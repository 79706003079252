import React, { useState, useEffect } from 'react'
import {BrowserRouter, Routes, Route, Navigate, HashRouter, useLocation  } from 'react-router-dom'

import { Container } from '@mui/material'
import { verifyUser } from './actions/auth'
import { useStateContext } from './context/ContextProvider';

import Izdaja from './pages/Izdaja'
import DodajBlago from './pages/Dodajblago'
import IzberiBlago from './pages/IzberiBlago'
import SuccessSummary from './pages/SuccessSummary'
import Partner from './pages/Partner'
import DodajPartnerja from './pages/DodajPartnerja'
import Auth from './pages/Auth'
import Pregled from './pages/Pregled' 
import Unauthenticated from './pages/Unauthenticated'


const App = () => {
  //const [idKreiranegaRacuna, setIdKreiranegaRacuna] = useState(null);
  //const [user, setUser] = useState(localStorage.getItem('profile'));
  const { user, setUser } = useStateContext();
  const naiID = '62f0fbdce27833ed5a1cf916';

  
  //const location = useLocation();
  //const searchParams = new URLSearchParams(location.search);
  //const formDataParam = searchParams.get('r');
  //console.log(location.search)
  //console.log(searchParams)

  /*useEffect(() => {
    console.log('preveriUserja')
    const user = localStorage.getItem('profile');
    if (user)
      if(verifyUser()){
        setUser(user);
        setUserId(JSON.parse(user).user._id);
      }
  }, [user, setUserId]);
*/

useEffect(() => {

  

  const storedUser = localStorage.getItem('profile');
console.log('app.j v')
  if (storedUser && verifyUser()) {
    setUser(JSON.parse(storedUser));
  } else {
    setUser(null);
  }
}, []);


  /*return (
    <Container maxWidth="xl">
      <HashRouter>
        <Routes>
          <Route path="" element={<Auth setUser={setUser} />} />

          <Route path="/izdaja" element={user ? <Izdaja />: <Navigate to="/" />} />        
          <Route path="/pregled" element={user ? <Pregled />: <Navigate to="/" />} />

        </Routes>
      </HashRouter>
    </Container>
  )*/
    return (
      <Container maxWidth="xl">
        <BrowserRouter>
          <Routes>
            {/* Osnovna pot */}
            <Route path="/" element={<Auth setUser={setUser} />} />
    
            {/* Pot do Izdaja, če je uporabnik prijavljen */}
            <Route path="/izdaja" element={user ? <Izdaja /> : <Navigate to="/" />} />        
    
            {/* Pot do Pregled, če je uporabnik prijavljen */}
            <Route path="/pregled" element={user ? <Pregled /> : <Navigate to="/" />} />
          </Routes>
        </BrowserRouter>
      </Container>
    );



}
//<Route path="/izdaja" element={user ? <Izdaja user={user} setIdKreiranegaRacuna={setIdKreiranegaRacuna}/>: <Unauthenticated />} />

/*
  <Route path="/dodaj-blago" element={user ? <DodajBlago />: <Unauthenticated />} />

          <Route path="/izberi-blago" element={user ? <IzberiBlago />: <Unauthenticated />} />
          <Route path="/izberi-blago/:index" element={user ? <IzberiBlago />: <Unauthenticated />} />


          <Route path="/success" element={user ? <SuccessSummary id={idKreiranegaRacuna} />: <Unauthenticated />} />

          <Route path="/partner" element={user ? <Partner />: <Unauthenticated />} />

          <Route path="/dodaj-partnerja" element={user ? <DodajPartnerja />: <Unauthenticated />} />
          
          <Route path="/pregled/nai" element={user && userId===naiID ? <NaiPregled user={JSON.parse(user)} naiID={naiID} />: <Navigate to='/pregled' replace /> } />
          
*/
export default App