import React, { useState, useEffect } from 'react'
import { Grow, Box, Grid, Typography, TextField, Button, useMediaQuery, Avatar, InputAdornment, IconButton  } from '@mui/material'
import { CPaper } from '../styles/themes'
import { useLocation, useNavigate } from 'react-router-dom'
import { signin, verifyUser, updateLastSeen } from '../actions/auth'
import toast, { Toaster } from 'react-hot-toast'

import LockOutlinedIcon from '@mui/icons-material/LockOutlined'
import Visibility from '@mui/icons-material/Visibility'
import VisibilityOff from '@mui/icons-material/VisibilityOff'

const Auth = ({ setUser }) => {
    const navigate = useNavigate()
    const location = useLocation();
    const size = useMediaQuery('(max-width:600px)'); //phone 
    const paperStyle = { width: size ? '90%' : '50%', padding: '1.5rem' };

    const [showPassword, setShowPassword] = useState(false);
    const [form, setForm] = useState({ username: '', password: '' });

    const handleChange = (e) => {
        setForm({ ...form, [e.target.name]: e.target.value });
    }

    const handleSubmit = async (e) => {
        e.preventDefault();
        console.log('paramsMoj:'); console.log(location.search);
        const success = await signin(form);
        if (success){
            updateLastSeen();
            setUser(localStorage.getItem('profile'));
            navigate(`/izdaja${location.search}`, { replace: true });
        }
        else
            toast.error('Nepravilno uporabniško ime ali geslo', { position: 'top-center', autoClose: 3000 });
    }

    useEffect(() => {
        const user = localStorage.getItem('profile');
 
        if (user)
            if(verifyUser())
                navigate(`/izdaja${location.search}`);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    return (
        <Grow in>
            <Box component="div" sx={{ display:'flex', alignItems:'flex-start', justifyContent:'center', marginTop:'8rem' }}>
                <Toaster />
                <CPaper elevation={24} sx={paperStyle}>
                <style>
                    {`
                    input {
                        padding-left: 7px !important;
                    }
                    `}
                </style>
                    <form onSubmit={handleSubmit}>
                        <Grid container spacing={2} sx={{ display:'flex', justifyContent:'center', alignItems:'center' }}>
                            <Avatar sx={{ bgcolor:'darkblue', margin: 'auto', width: '45px', height: '45px' }}>
                                <LockOutlinedIcon/>
                            </Avatar>
                            <Grid item xs={12} sx={{padding: '10px 0 0 0!important'}}>
                                <Typography fontSize='20px' align="center">Log in</Typography>
                            </Grid>
                            <Grid item xs={12}>
                                <TextField type="text" name="username" label="Username" variant="standard" required fullWidth onChange={handleChange} />
                            </Grid>
                            <Grid item xs={12}>
                                <TextField type={showPassword===false?'password':'text'} name="password" label="Password" variant="standard" required fullWidth
                                    onChange={handleChange}
                                    InputProps={{
                                        endAdornment: (
                                            <InputAdornment position="end">
                                                <IconButton
                                                    aria-label="toggle password visibility"
                                                    onClick={() => setShowPassword(!showPassword)}
                                                    onMouseDown={(e) => e.preventDefault()}
                                                >
                                                    {showPassword ? <Visibility /> : <VisibilityOff />}
                                                </IconButton>
                                            </InputAdornment>
                                        ),
                                    }}
                                 />
                            </Grid>
                            <Grid item xs={12} mt="25px">
                                <Button type="submit" variant="contained" color="primary" fullWidth>Log In</Button>
                            </Grid>
                        </Grid>
                    </form>
                </CPaper>
            </Box>
        </Grow>
    )}

export default Auth