import React, { useState, useEffect } from 'react';
import { Box, Button, Grid, ThemeProvider } from '@mui/material';
import { DataGrid, gridClasses, GridToolbar  } from '@mui/x-data-grid';
import { getRacuni } from '../actions/racuni';
import { getPdf } from '../actions/pdf';
import { sendMails } from '../actions/mail';
import DownloadIcon from '@mui/icons-material/Download';
import PictureInPictureIcon from '@mui/icons-material/PictureInPicture';
import { CPaper, themeMain } from '../styles/themes';
import Tooltip from '@mui/material/Tooltip';

import { useNavigate } from 'react-router-dom';
import Navbar from '../components/Navbar';

const columns = [
  {
    field: '_id',
    headerName: 'id',
    width: 80,
    hide: true
  },
  {
    field: 'stRacuna',
    headerName: 'Št. računa',
    width: 130
  },
  {
    field: 'referenca',
    headerName: 'Referenca',
    width: 130,
  },
  {
    field: 'stNarocila',
    headerName: 'Št. naročila',
    width: 130,
  },
  {
    field: 'partner',
    headerName: 'Partner',
    width: 130,
  },
  {
    field: 'davcnaStevilka',
    headerName: 'Davčna',
    width: 130,
  },
  {
    field: 'datumIzdaje',
    headerName: 'Datum izdaje',
    width: 135,
  },
  {
    field: 'datumOprStoritve',
    headerName: 'Datum storitve',
    description: 'Datum opravljene storitve',
    width: 140,
  },
  {
    field: 'rokPlacila',
    headerName: 'Rok plačila',
    width: 130,
  },
  {
    field: 'ddvs',
    headerName: 'DDV',
    width: 130,
    hide: true,
  },
  {
    field: 'skupajBrezDDV',
    headerName: 'Brez DDV',
    width: 120,
  },
  {
    field: 'skupajZaPlacilo',
    headerName: 'Za plačilo',
    width: 100,
  },
  {
    field: 'pdf',
    headerName: '',
    width: 109,
    renderCell: (rowData) => {
      return (
        <div style={{width:'100%', display: 'flex', justifyContent: 'end', visibility: rowData.row.stRacuna == '0'? 'hidden' : 'visible' }}>
          <Tooltip title="Prenesi PDF na računalnik" >
            <Box component='button' sx={{ backgroundColor: 'inherit', border: 'none', "&:hover": { cursor: 'pointer' } }} onClick={() => handleDownloadPdf(rowData)}>
              <DownloadIcon />
            </Box>
          </Tooltip>
          <Tooltip title="Odpri PDF" >
            <Box component='button' sx={{ backgroundColor: 'inherit', border: 'none', "&:hover": { cursor: 'pointer' } }} onClick={() => handleOpenPdf(rowData)}>
              <PictureInPictureIcon />
            </Box>
          </Tooltip>
        </div>
      );
    }
  },
  {
    field: 'creator',
    headerName: 'Ustvaril',
    width: 130,
    hide: true
  },
];

const handleDownloadPdf = (rowData) => {
  getPdf(rowData.row.stRacuna, rowData.row.creator, rowData.row._id, true);
}

const handleOpenPdf = (rowData) => {
  getPdf(rowData.row.stRacuna, rowData.row.creator, rowData.row._id, false);
}

export default function DataGridDemo() {
  const navigate = useNavigate();
  //eslint-disable-next-line
  const [racuni, setRacuni] = useState([]);
  const [modifiedRacuni, setModifiedRacuni] = useState([]);
  const [selectedRacuni, setSelectedRacuni] = useState([]);

  useEffect(() => {
    getRacuni().then((res) => {
      setRacuni(res);
    });
  }, []);

  useEffect(() => {
    setModifiedRacuni(racuni.map((racun) => {
      let ddvsString = '';
      racun.diffDDVs.forEach((ddv) => {
        ddvsString += ddv + '% ';
      });
      return {
        ...racun,
        partner: racun.partner.naziv,
        davcnaStevilka: racun.partner.davcnaStevilka,
        stStoritev: racun.storitve.length,
        ddvs: ddvsString
      }
    }));
  }, [racuni]);

  const handleSend = () => {
    let selectedString = '';
    selectedRacuni.forEach((racun) => {
      selectedString += racun + ',';
    });
    const recipient = prompt('Vnesite e-mail naslov prejemnika:');
    if (recipient && recipient.includes('@') && selectedString.length > 0) {
      sendMails(selectedString, recipient);
    } else {
      alert('Napačen e-mail naslov ali ni izbranih računov.');
    }
  }

  function currentlySelected(selections) {
    setSelectedRacuni(selections);
  }

  return (
    <ThemeProvider theme={themeMain}>
      <Box sx={{ width: '100%', paddingBottom: '15px', paddingTop: '15px' }}>
        <Grid container spacing={2} >

          <Grid item xs={12}>
            <Navbar />
          </Grid>
          <Grid item xs={12}  >
            <CPaper xs={12} sx={{   boxShadow: 'rgb(173 173 173 / 20%) 0px 0px 28px 0px', padding: '0.5rem 1.3rem 1.3rem 1.3rem' }} >
              <DataGrid
                sx={{
                  height: 629,
                  border: 0,
                  '& .MuiDataGrid-columnSeparator': { display: 'none' },
                  [`& .${gridClasses.cell}:focus, & .${gridClasses.cell}:focus-within`]: {
                    outline: 'none',
                  },
                  [`& .${gridClasses.columnHeader}:focus, & .${gridClasses.columnHeader}:focus-within`]:
                  {
                    outline: 'none',
                  },
                }}
                rows={modifiedRacuni}
                getRowId={(row) => row._id}
                columns={columns}
                focus
                pageSize={10}
                rowsPerPageOptions={[10]}
                loading={false}
                checkboxSelection
                disableSelectionOnClick
                onSelectionModelChange={currentlySelected}
                initialState={{
                  sorting: {
                    sortModel: [{ field: '_id', sort: 'desc' }],
                  },
                }}
                slots={{
                  toolbar: GridToolbar,
                }}
              />
    
                <Button variant="contained" size='small' style={{padding: '4px 13px'}} color="primary" onClick={handleSend}>Pošlji izbrane</Button>
               
            </CPaper>
          </Grid>
        </Grid>
      </Box>
    </ThemeProvider>
  );
}