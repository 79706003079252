import React from 'react'
import { AppBar, Toolbar, Button, useMediaQuery} from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { useStateContext } from '../context/ContextProvider';

import Logo from '../assets/crnejLogo.png'
import LogoutIcon from '@mui/icons-material/Logout';
import SettingsIcon from '@mui/icons-material/Settings';
import HomeIcon from '@mui/icons-material/Home';
import FeaturedPlayListOutlinedIcon from '@mui/icons-material/FeaturedPlayListOutlined';

import Tooltip from '@mui/material/Tooltip';
 
const Navbar = () => {
    //const { setUserId } = useStateContext();
    const isMobile = useMediaQuery('(max-width:600px)');  //check if screen is mobile
    const navigate = useNavigate();

    const handleLogout = () => {
        localStorage.removeItem('profile');
        navigate('/');
    }

    return (
        <AppBar position="static" color="inherit" sx={{ borderRadius:1, boxShadow: 'rgb(173 173 173 / 20%) 0px 0px 28px 0px',   display:'flex', flexDirection:'row', justifyContent:{sm:'center', md:'space-between'}, alignItems:'center', padding:'16px 20px 18px 28px' }}>
            <div style={{ display:'flex', alignItems:'center' }}>
                {!isMobile && (
                   <img src={Logo} alt="Soft logo" style={{marginBottom:'4px'}}  /> 
                 )}
                 
                
              
            </div>
            <div>
                <Button variant="contained" size='small' color="primary" style={{padding: '4px 13px'}} onClick={() => navigate('/izdaja')}>
                   DOMOV
                </Button>
                <Button variant="contained" size='small' color="primary" style={{marginLeft:'23px', padding: '4px 13px'}} onClick={() => {navigate('/pregled')}}>
                    PREGLED
                </Button>
                <Tooltip title="Nastavitve TODO">
                <Button variant="text"   color="primary" style={{marginLeft:'27px', minWidth:'20px' }} >
                    <SettingsIcon   />
                </Button>
                </Tooltip>
                <Tooltip title="Odjava">
                <Button variant="text"   color="primary" style={{marginLeft:'17px', minWidth:'20px' }} onClick={handleLogout}>
                    <LogoutIcon   />
                </Button>
                </Tooltip>
            </div>
        </AppBar>
    )
}

//setUserId(JSON.parse(localStorage.getItem('profile')).user._id);

export default Navbar